<template>
  <div>
    <input-autocomplete
      label="Cedente"
      :disabled="disableCedente"
      v-model="model.cedente"
      data-cy="cedentes"
      return-object
      :onRead="loadComboCedentes"
      @change="changeCedente"
      no-data-text="Nenhum cedente foi encontrado."
      required
      clearable
      style="z-index: 99"
    />
  </div>
</template>

<script>
export default {
  data () {
    return {
      cedentesCarteiras: []
    };
  },
  props: {
    value: Object,
    disableCedente: { type: Boolean, default: false }
  },
  computed: {
    model: {
      get () {
        var obj = this.value;
        return obj;
      },
      set (value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    loadComboCedentes (dto, callback) {
      const th = this;
      th.cedentes = [];
      th.$api.Cedentes.Combo(false).then(function (response) {
        if (!response.length || response.length === 0) {
          // eslint-disable-next-line standard/no-callback-literal
          callback([]);
        } else {
          callback(
            response.map(p => {
              return {
                value: p.guid,
                text: p.string
              };
            })
          );
        }
      });
    },
    changeCedente (val) {
      if (val) this.$emit('changeCedente', val.value);
    }
  }
};
</script>
