<template>
  <div id="app">
    <!--Aqui irá o sitemap-->

    <component v-bind:is="layout">
      <template slot="toolbar-timer">
        <retaguardaTimer />
      </template>
      <template slot="toolbar-avatar">
        <v-avatar style="cursor: pointer" size="85" class="ml-3" tile data-cy="toolbar-avatar">
          <v-img :src="logoImage" width="100%" contain data-cy="toolbar-img" />
        </v-avatar>
      </template>
      <template slot="toolbar-title">
        <v-toolbar-title style="cursor: pointer" color="accent" class="pl-3 pr-2" data-cy="toolbar-title">
          {{ tituloNavbar }}
        </v-toolbar-title>
      </template>
    </component>

    <notifications group="notifications-bottom-right" position="bottom right" classes="custom-notification" />

    <BlockUI v-if="loading" id="ui-loader-1234">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </BlockUI>
  </div>
</template>

<script>
import "shared/styles/variables.scss";
// layout em branco da página index
import IndexLayout from "@bmp-vue/core/layouts/IndexLayout";

// layout do sistema
import AppLayout from "@bmp-vue/core/layouts/AppLayout";

export default {
  components: {
    AppLayout,
    retaguardaTimer: () => import("/src/components/dbs/CoreTimer.vue")
  },
  data() {
    return {
      userIdentificador: ''
    }
  },
  metaInfo: {
    title: process.env.VUE_APP_BANCO,
    titleTemplate: "%s - Admin"
  },
  name: "App",
  data() {
    return {
      isLoaded: false,
      signedIn: false,
      logoImage: require("assets/images/logo.svg"),
      mouseXOldValue: null,
      mouseYOldValue: null,
      statusOperadorLogado: "loggedOut",
      contadorOperadorAusenteRodando: false,
      contadorOperadorAusente: process.env.VUE_APP_MOUSE_IDLE_SESSION_TIME,
      tituloNavbar: `274`
    };
  },
  mounted() {
    var vm = this;
    vm.userIdentificador = vm.$store.getters.user.identificador

    var modules = require('@/plugins/store/modules').default
    modules.forEach(p => {
      vm.$store.registerModule(p.name, p)
    })

    vm.statusOperadorLogado = localStorage.getItem('status')

    vm.$store.commit("isMobile");
    if (!vm.$store.getters.isMobile && !vm.$store.getters.drawer) {
      vm.$store.commit("drawer");
    }

    window.addEventListener("mousemove", (event) => {
      if (localStorage.getItem("status") == "loggedIn") {
        if (event.movementY != vm.mouseXOldValue || event.movementX != vm.mouseXOldValue) {
          vm.contadorOperadorAusente = process.env.VUE_APP_MOUSE_IDLE_SESSION_TIME
          vm.mouseXOldValue = event.movementX
          vm.mouseYOldValue = event.movementY
          if (!vm.contadorOperadorAusenteRodando) {
            vm.statusOperadorLogado = localStorage.getItem("status")
          }
        }
      }
    })

    window.addEventListener("resize", function () {
      vm.$store.commit("isMobile");
    });
  },
  methods: {
    verificar: function () {
      var vm = this;
      setTimeout(function () {
        if (!vm.loading && vm.signedIn) {
          vm.isLoaded = true;
        } else {
          vm.verificar();
        }
      }, 10);
    },
    contarTempoOperadorAusente() {
      var vm = this
      
      if (vm.contadorOperadorAusente > 0 && localStorage.getItem("status") === "loggedIn") {
        window.setTimeout(() => {
          vm.contadorOperadorAusente--
          vm.contarTempoOperadorAusente()
        }, 1000)
        vm.contadorOperadorAusenteRodando = true
      } else {
        vm.contadorOperadorAusenteRodando = false
        vm.$api['autenticacao'].Logout().then(function (response) { 
            if(vm.$api.UI.PostAction(response))
              vm.$store.commit('CLEAR_USER_DATA')            
            }
        ).catch() 
      }
    }
  },
  watch: {
    statusOperadorLogado(newValue) {
      if (newValue == "loggedIn") {
        this.contarTempoOperadorAusente()
      }
    }
  },
  computed: {
    loading() {
      return (
        this.$store.getters.loading || this.$store.getters.activeRequests > 0
      );
    },
    layout() {
      this.$store.commit("SET_LAYOUT", IndexLayout);
      if (
        !this.$route.meta.anonymousAuth &&
        ["/"].indexOf(this.$route.path) < 0
      ) {
        this.$store.commit("SET_LAYOUT", AppLayout);
      }
      return this.$store.getters.layout;
    }
  }
};
</script>

<style>
  .v-main {
    background-color: rgb(245, 245, 245) !important;
  }

  * {
    scrollbar-width: auto;
    scrollbar-color: #a1a1a1 #ffffff;
  }

    *::-webkit-scrollbar {
      width: 16px;
    }

    *::-webkit-scrollbar-track {
      background: #ffffff;
    }

    *::-webkit-scrollbar-thumb {
      background-color: #cfcfcf;
      border-radius: 10px;
      border: 3px solid #ffffff;
    }
</style>
