export default [
  {
    name: 'onboarding',
    namespaced: true,
    state: {
      parceiros: []
    },
    mutations: {
      parceiros(state, array) {
        state.parceiros = array
      },
      LIMPAR_CHALLENGE() {
        localStorage.removeItem('challenge')
      }
    },
    actions: {
      limparChallenge({ commit }) {
        commit('LIMPAR_CHALLENGE')
      }
    },
    getters: {
      parceiros(state) {
        return state.parceiros
      }
    }
  }
]