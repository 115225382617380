export default
{
    TiposPessoa: [
        { value: 1, text: 'PF' }, 
        { value: 2, text: 'PJ' }
    ],
    ContextoOnboarding: [
        { value: 0, text: 'Nenhum' }, 
        { value: 1, text: 'Correntista' },
        { value: 2, text: 'Operador Backoffice' }, 
        { value: 3, text: 'Operador Retaguarda' },
        { value: 4, text: 'Operador Frontend' },
        { value: 5, text: 'Biometria Operador Retaguarda' },
        { value: 6, text: 'Biometria Operador FrontEnd'}
    ],
    Situacao: [
        { value: 1, text: 'Ativado' }, 
        { value: 2, text: 'Desativado' }
    ],
    TipoBureau: [
        { value: 1, text: 'Cadastro restritivo' },
        { value: 3, text: 'Óbito e fiscal' },
        { value: 4, text: 'Mídia desabonadora' },
        { value: 5, text: 'E-mail age' },
        { value: 6, text: 'Análise restritiva' },
        { value: 7, text: 'Criminal detalhe' },
        { value: 8, text: 'Indício fraude' }
    ],
    SituacaoConfiguracaoBureau: [
        { value: 0, text: 'Todos' },
        { value: 1, text: 'Ativado' }, 
        { value: 2, text: 'Desativado' }
    ],
    SolicitacoesAnalise: [
        { value: 1, text: 'Todas' },
        { value: 2, text: 'Em consulta' },
        { value: 3, text: 'Análises pendentes' },
        { value: 4, text: 'Aprovações pendentes' },
    ],
    TipoPermissao: [
        { value: 1, text: 'Criação' },
        { value: 2, text: 'Edição' },
        { value: 4, text: 'Criação Vínculo' },
        { value: 5, text: 'Edição Vínculo' }
    ],
    SituacaoExecucao: [
        { value: 1, text: 'Pendente' },
        { value: 2, text: 'Executando' },
        { value: 3, text: 'Executado' },
        { value: 4, text: 'Ignorado' },
        { value: 5, text: 'Validado' },
        { value: 99, text: 'Erro Sistema'}
    ],
    SituacaoAprovacaoAnalise:[
        { value: 1, text: 'Aprovado' },
        { value: 2, text: 'Reprovado' },
    ],
    SituacaoOnboaarding:[
        { value: 1, text: 'Pendente' },
        { value: 2, text: 'Aprovado' },
        { value: 3, text: 'Reprovado' },
    ],
    PerfilOperadorOnboardingExibicao:[
        { value: 2, text: 'Analista' },
        { value: 3, text: 'Analista' },
        { value: 4, text: 'Aprovador' },
        { value: 5, text: 'Aprovador' }
    ],
    SituacaoValidacaoOnboardingDetalhe:[
        { value: 1, text: 'Pendente Validacao' },
        { value: 2, text: 'Aprovado' },
        { value: 3, text: 'Reprovado' },
        { value: 98, text: 'Validacao Inexistente' },
        { value: 99, text: 'Erro Sistema' }
    ],
    DescricaoValidacaoOnboardingDetalhe:[
        { value: 1, text: 'Análise pendente de validação' },
        { value: 2, text: 'Aprovado' },
        { value: 3, text: 'Análise contém restrição' },
        { value: 98, text: 'Validação não encontrada, realize a consulta do bureau novamente' },
        { value: 99, text: 'Ocorreu um erro inesperado na análise' }
    ],
    ApiOrigem: [
        { value: 0, text: 'BackOffice' },
        { value: 1, text: 'API FrontEnd' },
        { value: 2, text: 'API Externa' },
        { value: 3, text: 'Retaguarda' },
        { value: 99, text: 'Outros' }
    ],
    StatusSolicitacoes: [
        { value: 2, text: 'Em consulta' },
        { value: 3, text: 'Análise pendente' },
        { value: 4, text: 'Aprovação pendente' },
    ]
}
