<template>
  <div>
    <ConfirmacaoAnalise @close="responseReconsulta" ref="confirmaConsulta"/>
    <div class="custom-div">
      <span>Situação bureau: {{ consultaBureau.msgSituacaoBureau }} </span>
      <span>Número execuções: {{ consultaBureau.numExecucao }}</span>
      <span>Data última consulta: {{ consultaBureau.dtSituacao }}</span>
    </div>
    <br />
    <div class="custom-div-btn">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn 
              v-on="on" 
              v-show="mostrarBotaoDownload" 
              :disabled="bloquearDownload"
              color="blue-grey" 
              @click="buscarArquivo"
              fab small dark><v-icon>mdi-download</v-icon></v-btn>
          </template>
          <span>Download arquivo</span>
        </v-tooltip>
      <span>&nbsp;&nbsp;</span>
        <v-tooltip bottom v-if="!isHistorico">
          <template v-slot:activator="{ on }">
            <v-btn 
              v-on="on" 
              :disabled="bloquearConsulta" 
              color="blue-grey" 
              @click="consultar"
              fab small dark><v-icon>mdi-database-search</v-icon></v-btn>
          </template>
          <span>Consultar novamente</span>
        </v-tooltip>
    </div>
    <br><br>
    <strong><span>{{ titulo }}</span></strong> 
    <br><br>
    <div>
      <div v-for="(valor, chave) in bureauDetalhes" :key="chave"> <strong>{{ chave }}</strong>: {{ valor }} </div>
    </div>

    <div v-show="mostrarInfosProcessoCriminalDetalhe()">
      <br><strong><span>Informações Processos:</span></strong><br><br>
      <div v-if="bureauDetalhesCriminalDetalheParticipantesLista.length > 0">
        <div v-for="(valor, chave) in bureauDetalhesCriminalDetalheLista" :key="chave">
          <span> <strong>Número:</strong>  {{ valor.Number }} </span><br>
          <span> <strong>Tipo:</strong>  {{ valor.Type }} </span><br>
          <span> <strong>Assunto Principal:</strong>  {{ valor.MainSubject }} </span><br>
          <span> <strong>Tipo de Tribunal:</strong>  {{ valor.CourtType }} </span><br>
          <span> <strong>Situação:</strong>  {{ valor.Status }} </span><br>
          <div v-for="(valorLista, chaveLista) in valor.Participantes" :key="chaveLista"> 
            <span> <strong>Nome {{ definirTipoParticipanteCriminalDetalhe(valorLista.Polarity) }}:</strong>  {{ valorLista.Name }} </span><br>
          </div><br>
        </div><br><br> 
      </div> 
      <div v-else>
          <span>Não existem processos criminais para este operador.</span><br><br>
      </div>    
    </div>
  </div>
</template>

<script>
export default {
  props: {
    refresh: Function,
    bureau: Object,
    onboarding: Object,
    contexto: String,
    isHistorico: Boolean
  },
  components: {
    ConfirmacaoAnalise: () => import('../ConsultaIndividualConfirmacao.vue'),
  },
  data() {
    return {
      titulo: '',
      consultaBureau: {},
      bureauDetalhesCriminalDetalhe: {},
      bureauDetalhesCriminalDetalheParticipante: {},
      bureauDetalhesCriminalDetalheParticipantesLista:[],
      bureauDetalhesCriminalDetalheLista: [],
      bureauDetalhes: {},
      bloquearConsulta: false,
      bloquearDownload: false,
      mostrarBotaoDownload: false,
    }
  },
  methods: {
    consultar() {
      let th = this

      let dto = {
        codigoParceiro: th.onboarding.codigoParceiro,
        documentoFederal: th.onboarding.documentoFederal,
        contexto: th.onboarding.contexto,
        tipoBureau: th.consultaBureau.bureau,
        codigoOnboarding: th.onboarding.codigo
      } 
      th.$refs.confirmaConsulta.openModal(th.titulo, dto)
    },
    buscarArquivo() {
      let th = this

        th.$api['analise-onboarding'].BuscarArquivo(th.onboarding.codigo, th.consultaBureau.bureau).then(function (response) { 
              if(th.$api.UI.PostAction(response))
                th.downloadArquivo(response);                
            }
          );
        },
    downloadArquivo(arquivo) {
      let th = this
        
      const blob = new Blob([JSON.stringify(arquivo, null, 3)], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const formato = 'txt'

      const nomeArquivo = `Analise_DocumentoFederal_${th.onboarding.documentoFederal}.${formato}`
      link.setAttribute("download", nomeArquivo);
      document.body.appendChild(link);
      link.click();
    },
    preencherInformacoes() {
      let th = this
      Object.assign(th.consultaBureau, th.bureau)
      
      th.consultaBureau.dtSituacao = th.$api.UI.Format.DateTime(th.consultaBureau.dtSituacao)

      if (th.consultaBureau.situacaoExecucao == th.$api.Arrays.SituacaoExecucao[2].value
          || th.consultaBureau.situacaoExecucao == th.$api.Arrays.SituacaoExecucao[4].value) {
        th.bloquearConsulta = false
        th.bureauDetalhes = JSON.parse(th.consultaBureau.jsonRetornoBureauTratado ?? "{}")
        th.titulo = `${th.$api.Arrays.TipoBureau.find((arr) => arr.value == this.consultaBureau.bureau).text}:`

        th.formatarObitoFiscal()
        th.formatarMidia()
        th.formatarCriminalDetalhe()
      }

      if (th.consultaBureau.situacaoExecucao == th.$api.Arrays.SituacaoExecucao[0].value
        || th.consultaBureau.situacaoExecucao == th.$api.Arrays.SituacaoExecucao[1].value) {
        th.bloquearConsulta = true
        th.bloquearDownload = true
        th.mostrarBotaoDownload = false
        th.bureauDetalhes = {}
        th.titulo = 'Consulta em andamento, por favor aguarde.'
      }

      if (th.consultaBureau.situacaoExecucao == th.$api.Arrays.SituacaoExecucao[3].value) {
        th.bloquearDownload = true
        th.bloquearConsulta = false
        th.bureauDetalhes = {}
        th.titulo = 'Ocorreu um erro na consulta, por favor tente novamente.'
      }
      const bureauCriminalResumo = th.$api.Arrays.TipoBureau.find((tb) => tb.text.toLowerCase() == 'criminal resumo').value
      const bureauCriminalDetalhe = th.$api.Arrays.TipoBureau.find((tb) => tb.text.toLowerCase() == 'análise restritiva').value
      
      if (th.consultaBureau.bureau == bureauCriminalResumo
        || th.consultaBureau.bureau == bureauCriminalDetalhe)
        th.mostrarBotaoDownload = true
    },
    response() {
      let th = this
      th.show = false
    },
    responseReconsulta() {
      let th = this
      th.$emit('reconsulta')
      th.show = false
    },
    mostrarInfosProcessoCriminalDetalhe(){
      let th = this
      return th.consultaBureau.bureau == th.$api.Arrays.TipoBureau.find((tb) => tb.text.toLowerCase() == 'criminal detalhe').value &&
              th.consultaBureau.situacaoExecucao == th.$api.Arrays.SituacaoExecucao.find((te) => te.text.toLowerCase() == 'validado').value
    },
    formatarCriminalDetalhe() {
      let th = this
      const bureauCriminalDetalhe = th.$api.Arrays.TipoBureau[5].value

      if (th.consultaBureau.bureau == bureauCriminalDetalhe){
        th.listarAcoesCriminais()
        delete th.bureauDetalhes['Ações criminais']
        delete th.bureauDetalhes['Parties']
      }
    },
    listarAcoesCriminais(){
      let th = this
      for (const item of th.bureauDetalhes['Ações criminais']) 
        {
          Object.assign(th.bureauDetalhesCriminalDetalhe, item) 
          th.listarParticipantes()            
          th.bureauDetalhesCriminalDetalheLista.push(th.bureauDetalhesCriminalDetalhe)
          th.bureauDetalhesCriminalDetalhe = {}
        }
    },
    listarParticipantes(){
      let th = this
      for (const itemParticipante of th.bureauDetalhesCriminalDetalhe['Parties']) 
          {
            Object.assign(th.bureauDetalhesCriminalDetalheParticipante, itemParticipante)                  
            th.bureauDetalhesCriminalDetalheParticipantesLista.push(th.bureauDetalhesCriminalDetalheParticipante)
            th.bureauDetalhesCriminalDetalheParticipante = {}
          }
          th.bureauDetalhesCriminalDetalhe.Participantes = th.bureauDetalhesCriminalDetalheParticipantesLista
    },

    definirTipoParticipanteCriminalDetalhe(valor){   
      return valor.toLowerCase() == 'active' ? 'Autor' : 'Réu'
    },
    formatarObitoFiscal() {
      let th = this
      const bureauObitoFiscal = th.$api.Arrays.TipoBureau[1].value

      if (th.consultaBureau.bureau == bureauObitoFiscal)
        th.bureauDetalhes['Indicação de óbito'] = th.bureauDetalhes['Indicação de óbito'] == false ? 'NEGATIVO' : 'POSITIVO'
    },
    formatarMidia() {
      let th = this
      const informacoesNaoEncontradas = 'Informação não encontrada.'
      const camposMidia = ['Total de páginas', 'Total de notícias no último ano', 'Total de notícias no último semestre', 'Total de notícias no último trimestre', 'Total de notícias nos últimos 30 dias', 'Total de notícias nos últimos 7 dias', 'Total de notícias']
      const bureauMidia = th.$api.Arrays.TipoBureau[2].value

      if (th.consultaBureau.bureau != bureauMidia) return

      for (let contador = 0; contador < camposMidia.length; contador++)
        th.bureauDetalhes[camposMidia[contador]] = th.bureauDetalhes[camposMidia[contador]] < 0 ? informacoesNaoEncontradas : th.bureauDetalhes[camposMidia[contador]]

      th.bureauDetalhes['Nível de exposição midiática'] = !th.bureauDetalhes['Nível de exposição midiática'] ? 0 : th.bureauDetalhes['Nível de exposição midiática']
      th.bureauDetalhes['Nível de celebridade'] = !th.bureauDetalhes['Nível de celebridade'] ? 0 : th.bureauDetalhes['Nível de celebridade']
      th.bureauDetalhes['Nível de impopularidade'] = !th.bureauDetalhes['Nível de impopularidade'] ? 0 : th.bureauDetalhes['Nível de impopularidade']  
    }
  },
  created() {
    const th = this;
    th.preencherInformacoes()

    th.$watch('bureau', () => {
      th.preencherInformacoes();
    }, { deep: true })
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.custom-div{
  background-color: rgb(243, 236, 236);
  padding: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between
}

.custom-div-btn{
  display: flex;
  justify-content: flex-end;
  margin-bottom: -70px;
  margin-right: 10px;
}

.v-stepper__wrapper{
  padding-bottom: 30px;
}
</style>