import util from '@bmp-vue/core/api/util'

const controller = 'onboarding-config'
const versao = 'v1/'

export default Object.assign({}, util.GetCrud(controller, null), {
    BuscarTodos: function (dto, options) {
        if (options && options.data) dto.data = options.data
        return util.Axios.GetAll(versao + controller, dto)
    },
    BuscarAnalisesVigentes: (situacao) => {
        return util.Axios.Get(versao + controller + `/analises-vigentes?situacaoAnaliseVigente=${situacao}`)
    },
    BuscarPorCodigo: (codigo) => {
        return util.Axios.Get(versao + controller + `/${codigo}`)
    },
    Inserir: (dto) => {
        return util.Axios.Post(versao + controller, dto)
    },
    Atualizar: (codigo, request) => {
        return util.Axios.Put(versao + controller, codigo, request)
    }
})