<template>
  <div v-if="!somenteConta">
    <v-row>
      <v-col cols="6" md="6">
        <input-autocomplete
          v-if="!model.codigo"
          label="Conta"
          v-model="model.codigoConta"
          return-object
          :onRead="loadComboContas"
          no-data-text="Não existem contas cadastradas para esta agência"
          @change="changeConta"
          :required="contaRequired"
          clearable
        />
        <input-text v-else label="Conta" v-model="model.contaNumero" disabled />
      </v-col>
      <v-col cols="6">
        <input-text label="Cliente" v-model="model.nome" disabled />
      </v-col>
      <v-col cols="6">
        <input-documento
          label="CPF/CNPJ"
          :value="$api.UI.Format.DocumentoFederal(model.documentoFederal)"
          disabled
        />
      </v-col>
      <v-col cols="6">
        <input-text label="Tipo Pessoa" v-model="tipoPessoa" disabled />
      </v-col>
      <v-col cols="6" v-if="showModeloConta">
        <input-text label="Modelo Conta" v-model="modeloConta" disabled />
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-col cols="12">
      <input-autocomplete
        v-if="!model.codigo"
        label="Conta"
        v-model="model.codigoConta"
        return-object
        :onRead="loadComboContas"
        no-data-text="Não existem contas cadastradas para esta agência"
        @change="changeConta"
        :required="contaRequired"
        clearable
      />
      <input-text v-else label="Conta" v-model="model.contaNumero" disabled />
    </v-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contas: [],
      contaLoaded: false,
    };
  },
  props: {
    value: Object,
    codigoAgenciaSelected: String,
    codigoContaSelected: String,
    contaRequired: { type: Boolean, default: true },
    somenteConta: { type: Boolean, default: false },
    showModeloConta: { type: Boolean, default: false }
  },
  computed: {
    model: {
      get() {
        var obj = this.value;
        return obj;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    tipoPessoa() {
      var obj = this.$api.UI.Format.SomenteNumeros(this.model.documentoFederal);
      obj = this.$api.Arrays.TiposPessoa.find(
        (p) => p.value === (obj ? (obj.length <= 11 ? 1 : 2) : 0)
      );
      return obj ? obj.text : "";
    },
    modeloConta() {
      let obj = this.$api.Arrays.ModeloConta.find(
        (p) => p.value === this.model.modeloConta
      );
      return obj ? obj.text : "";
    },
  },
  watch: {
    codigoContaSelected(val) {
      if (val && this.codigoAgenciaSelected) {
        this.model.agencia = this.agencias.find(
          (a) => a.value === this.codigoAgenciaSelected
        );
        this.changeAgencia(this.model.agencia);
      }
    },
  },
  methods: {
    loadComboContas(dto, callback) {
      const th = this;
      th.$api.Contas.ComboContas(dto).then((data) => {
        if (!data.length || data.length === 0) {
          // eslint-disable-next-line standard/no-callback-literal
          callback([]);
        } else {
          callback(
            data.map((p) => {
              return {
                value: p.codigo,
                text: p.contaPgto,
                descricao: p.contaPgto + " - " + p.descricao,
                agencia: p.codigoAgencia,
                modeloConta: p.modeloConta,
              };
            })
          );
        }
      });
    },
    changeConta(val) {
      const th = this;
      th.$set(th.model, "nome", "");
      th.$set(th.model, "documentoFederal", "");
      th.$set(th.model, "tipoPessoa", null);
      th.$set(th.model, "modeloConta", null);

      if (val) {
        th.model.codigoConta = val.value;
        th.model.contaPagador = val.descricao;
        th.model.codigoAgencia = val.agencia;
        th.model.modeloConta = val.modeloConta;

        const dto = { parameters: [{ name: "codigoConta", value: val.value }] };

        th.$api.ContaCorrentistas.GetAll(dto).then((data) => {
          if (Array.isArray(data)) {
            data.forEach((item) => {
              if (item.titularidade === 1) {
                th.$set(th.model, "nome", item.nome);
                th.$set(th.model, "details", item);
                th.$set(
                  th.model,
                  "documentoFederal",
                  th.$api.UI.Format.DocumentoFederal(item.documentoFederal)
                );

                th.$set(th.model, "tipoPessoa", item.tipoPessoa);
                th.$set(th.model, "modeloConta", item.modeloConta);
              }
            });
          }
        });
      }
    },
    carregaAgencia(val, callback) {
      this.$api.Agencias.Get(val).then((response) => {
        if (callback) callback(response);
      });
    },
  },
};
</script>
