<template>
  <div id="captcha" class="g-recaptcha" ></div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    value: Object
  },
  computed: {
    model: {
      get () {
        var obj = this.value
        return obj
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
      async execute () {
      let th = this
      try {
        // eslint-disable-next-line no-undef
        grecaptcha.enterprise
          .execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY)
          .then(function (token) {
            th.verifyCallback(token)
          })

          let elapsedTime = 0
          while(!this.model.TokenCaptcha && elapsedTime < 3000){
            elapsedTime += 500
            await new Promise(r => setTimeout(r, 1000));
          }
      } catch (e) {
      }
    },
    reset () {
      try {
        this.execute()
      } catch (e) {
      }
    },
    verifyCallback (tokenCaptcha) {
      this.model.TokenCaptcha = tokenCaptcha
    }
  }
}
</script>

<style>
  .grecaptcha-badge { 
      visibility: hidden;
  }
</style>