import util from '@bmp-vue/core/api/util'

const controller = 'analise-onboarding'
const versao = 'v1'

export default Object.assign({}, util.GetCrud(controller, null), {
    BuscarTodos: function (dto) {
        return util.Axios.GetAll(`${versao}/${controller}`, dto)
    },
    AnaisesConcluidas: function (dto) {
        return util.Axios.GetAll(`${versao}/${controller}/analises-concluidas`, dto)
    },
    ObterConsulta: function (codigoOnboarding) {
        return util.Axios.Get(`${versao}/${controller}`, codigoOnboarding)
    },
    RealizarAnalise: function (dto) {
        return util.Axios.Post(`${versao}/${controller}`, dto)
    },
    RealizarAnaliseIndividual: function (dto) {
        return util.Axios.Post(`${versao}/${controller}/analise-individual`, dto)
    },
    BuscarArquivo: function (codigoOnboarding, tipoBureau) {
        return util.Axios.Get(`${versao}/${controller}/${codigoOnboarding}/download-analise?tipoBureau=${tipoBureau}`)
    },
    BuscarConsultaCAF: function (codigoOnboarding) {
        return util.Axios.Get(`${versao}/${controller}/${codigoOnboarding}/consulta-caf`)
    },
    BuscarImagensKYC: function (codigoOnboarding, origemBiometria) {
        return util.Axios.Get(`${versao}/${controller}/${codigoOnboarding}/link-imagens-kyc?origemBiometria=${origemBiometria}`)
    }
})