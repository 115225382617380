<template>
  <div>
    <b><span>Análise Geral:</span></b>
    <br><br>
    <v-data-table :headers="headers" :items="itens" item-key="id">
      <template v-slot:items="props">
        <td>{{ props.item.nomeOperador }}</td>
        <td>{{ props.item.dtAprovacao }}</td>
        <td>{{ props.item.observacao }}</td>
        <td>{{ props.item.situacaoAprovacao }}</td>
        <td>{{ props.item.tipoPerfil }}</td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
    props: {
      refresh: Function,
      analises: Array
    },
    data() {
    return {
      headers: [
        { text: 'Operador Análise', value: 'nomeOperador' },
        { text: 'Tipo Perfil', value: 'tipoPerfil' },
        { text: 'Data Análise', value: 'dtAprovacao' },
        { text: 'Ação Análise', value: 'situacaoAprovacao' },
        { text: 'Observação', value: 'observacao' },
      ],
      itens: []
    }
    },
    methods: {
      alimentarObjeto() {
        const th = this
        th.itens= []
        if(th.analises.length == 0)
          return;

        th.itens = JSON.parse(JSON.stringify(th.analises));
        th.itens.forEach(curr => th.normalizarCampos(curr))
      },
      normalizarCampos(curr){
        const th = this

        curr.dtAprovacao = th.$api.UI.Format.DateTime(curr.dtAprovacao)
        curr.situacaoAprovacao = th.$api.Arrays.SituacaoAprovacaoAnalise.find((arr) => arr.value == curr.situacaoAprovacao).text
        curr.tipoPerfil = th.$api.Arrays.PerfilOperadorOnboardingExibicao.find((arr) => arr.value == curr.tipoPerfil).text  
      }
    }
}
</script>
  