<template>
  <modal-form :submit="submit" v-model="show" size="md">
    <template v-slot:title>{{ acao[0].toUpperCase() + acao.substring(1) }} de {{ tipo }} de operador {{
    contextoConfirmacao }}</template>
    <template v-slot:body>
      <v-row>
        <v-col cols="12">
          <div>
            <p class="atencao">Atenção!</p>
            <p class="aviso">
              Esta ação não tem retorno, uma vez confirmada a {{ acao }}, não é possível desfaze-la.
            </p>
            <p class="aviso">
              Confirma a {{ acao }} da análise para {{ tipo }} de operador {{ contextoConfirmacao }}?
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div>
            <label class="obs">Observação:</label>
            <textarea v-model="model.obs" class="area" required maxlength="255"></textarea>
          </div>
        </v-col>
      </v-row>
    </template>
  </modal-form>
</template>
<script>
export default {
  props: {
    contextoConfirmacao: String
  },
  components: {},
  data() {
    return {
      acao: 'acao',
      tipo: 'tipo',
      show: false,
      model: {}
    }
  },

  methods: {
    openModal(curr) {
      let th = this
      th.show = true
      Object.assign(th.model, curr)
      th.acao = th.model.acao
      th.tipo = th.obterTipoPermissao(th.model.tipoPermissao)
    },
    obterAcaoAprovacao(acao) {
      if (acao == 'aprovação') return 1
      else if (acao == 'reprovação') return 2
    },
    obterTipoPermissao(tipoPermissao) {
      if (tipoPermissao == 1) return 'criação'
      else if (tipoPermissao == 2) return 'edição'
      else if (tipoPermissao == 4) return 'vínculo'
      else if (tipoPermissao == 6) return 'autenticação facial'
    },
    submit() {
      let th = this

      let dto = {
        CodigoOnboarding: th.model.codigo,
        SituacaoAprovacao: th.obterAcaoAprovacao(th.acao),
        Observacao: th.model.obs,
      }
      
      th.$api['analise-onboarding'].RealizarAnalise(dto).then(function (response) {
        if (th.$api.UI.PostAction(response)) {
          th.$emit('close', true)
          th.show = false
        }
      });
    },
  }
}
</script>
<style scoped>
.atencao {
  color: #f44336;
  font-weight: bold;
}

.aviso {
  color: black;
}

.obs {
  color: black;
}

.area {
  color: black;
  width: 200%;
  outline: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
}
</style>
