import util from '@bmp-vue/core/api/util'

const controller = 'senha-operador'
const versao = 'v1/'

export default Object.assign({}, util.GetCrud(controller, ['none']), {
    AlterarSenhaOperador: (request) => {
        return util.Axios.Put(versao + controller + '/alterar','', request)
    },
    SolicitarLink: (request) => {
        return util.Axios.Post(versao + controller + '/solicitar-link', request)
    },
    ValidarTokenSenha: (username, token) => {
        return util.Axios.Post(versao + controller + `/validar-token?username=${username}&token=${token}`)
    }
})