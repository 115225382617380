<template>
        <v-btn color="primary">
          <a
            class="btn btn-primary btn-block"
            style="color: white; text-decoration: none"
            :href="model.sourceImage"
            download
            ><i class="fas fa-download"></i>&nbsp;Download</a
          >
        </v-btn>
</template>

<script>
export default {
  data () {
    return {
      model: {
        sourceImage: ''
      }
    }
  }
};
</script>
