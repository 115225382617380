<template>
  <input-text
    :required="required"
    :label="label"
    v-model="model"
    v-mask="['##.###.###/####-##']"
    :disabled="disabled"
  />
</template>

<script>
export default {
  props: {
    value: null,
    label: { default: "CNPJ" },
    required: { default: false },
    disabled: { default: false },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
  