import ui from '@bmp-vue/core/api/ui'
import util from '@bmp-vue/core/api/util'

export default {
    Auth: {
        ...util.Auth,
        RegisterToken: function (data, userName, extendUser) {
            if (ui.PostLoadData(data)) {
                if (data.accessToken) {
                    if (!extendUser) { extendUser = {} }
                    const user = {
                        ...extendUser,
                        email: data.email,
                        token: data.accessToken,
                        refreshToken: data.refreshToken,
                        roles: this.GetUserRole(data.accessToken),
                        expiresAt: new Date(
                            new Date().getTime() + data.expires * 1000
                        ),
                        identificador: userName || data.nomeUsuario || data.identificador,
                        chaveSegurancaAtiva: data.chaveFisicaValidada || false
                    }
                    return user
                } else return false
            } else { return false }
        },
        GetUserRole(token) {
            try {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                var roles = JSON.parse(jsonPayload).roles
                return JSON.parse(roles);
            } catch {
                const jwtData = token.split('.')[1]
                var jwtFormatado = jwtData.replace(/-/g, '+').replace(/_/g, '/');
                const decodedJwtJsonData = window.atob(jwtFormatado)
                return JSON.parse(decodedJwtJsonData).roles
            }
        }
    }
}