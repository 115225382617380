<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          tile
          class="text-subtitle-2"
          color="secondary"
          v-bind="attrs"
          data-cy="timer-button"
          v-on="on">
          {{ tempoRestanteSessao }}
          <v-icon class="pl-2" :color="clockColor" data-cy="timer-button-icon">mdi-timer-outline</v-icon>
        </v-btn>
      </template>

      <v-list dense data-cy="timer-list">
        <v-list-item link @click="refreshToken" data-cy="timer-list-item">
          <v-list-item-title data-cy="timer-list-item-title">
            <v-icon class="pr-2" color="primary" data-cy="timer-list-item-icon">mdi-refresh-circle</v-icon>
            Renovar Sessão
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <ValidarChaveSeguranca
      @proximoPassoValidacaoToken="reiniciarToken"
      ref="validarchaveseguranca"
    ></ValidarChaveSeguranca>
  </div>
</template>

<script>
export default {
    components: {
    ValidarChaveSeguranca: () => import('/src/views/mfafisico/ValidarChaveSeguranca.vue')
  },
  data () {
    return {
      tempoRestanteSessao: '',
      tempo: 0,
      clockColor: 'green',
      user: null,
      msg: 'Deseja renovar a sessão?',
      realizouDesafioChave: false
    }
  },
  methods: {
    startCountdown () {
      const th = this
      const isLogged = th.setExpirationTime()
      if (th.tempo > 0) {
        th.clockColor = 'green'
        let min = parseInt(th.tempo / 60)
        let seg = th.tempo % 60

        if (min < 10) {
          min = '0' + min
          min = min.substr(0, 2)
          if (min === 0) th.clockColor = 'amber'
          else th.clockColor = 'green'
        }
        if (seg <= 9) seg = '0' + seg

        th.tempoRestanteSessao = min + ':' + seg

        if (isLogged) {
          setTimeout(function () {
            th.startCountdown()
          }, 1000)
        }
      } else {
        th.clockColor = 'red'
        th.tempoRestanteSessao = '00:00'
        th.$api.UI.Confirmacao(
          'info',
          'Deseja renovar a sessão?',
          th.refreshToken,
          th.logout,
          'Sessão expirada'
        )
      }
    },

    setExpirationTime () {
      const status = localStorage.getItem('status')
      this.user = JSON.parse(localStorage.getItem('user'))
      if (status) {
        if (status === 'loggedIn') {
          const dataExpiracao = new Date(this.user.expiresAt)
          const dataAtual = new Date()
          this.tempo = Math.trunc(
            (dataExpiracao.getTime() - dataAtual.getTime()) / 1000
          )
        } else {
          this.$api.UTIL.Auth.Logout('confirma')
          return false
        }
      }
      return true
    },
    logout () {
      const th = this
      th.$api.Autenticacao.Logout().then(function (data) {
        if (th.$api.UI.PostLoadData(data)) {
          th.$api.UTIL.Auth.Logout()
        }
      })
        .catch((err) => console.log(err))
    },
    refreshToken () {
      const th = this

      if (th.user.chaveSegurancaAtiva) {
        th.$api['mfa-fisico'].SolicitarValidacaoChave().then(data => {
          if (data.sucess) {
            setTimeout(() => {
              if (!this.realizouDesafioChave) th.logout()
            }, 15000)

            th.realizouDesafioChave = false

            localStorage.setItem('challenge', JSON.stringify(data))
            th.$refs.validarchaveseguranca.openModal()
          } else {
            setTimeout(th.logout(), 3000)
          }
        })
      } else {
        th.reiniciarToken()
      }
     },
     reiniciarToken(challengeResponse) {
      let th = this
      th.$store.dispatch('onboarding/limparChallenge')

      if (challengeResponse) th.realizouDesafioChave = true

      th.$api.Autenticacao.RefreshToken(th.user.refreshToken)
        .then(function (data) {
          data.chaveFisicaValidada = th.user.chaveSegurancaAtiva;
          const user = th.$api.UTIL.Auth.RegisterToken(data, th.user.identificador)
          if (user) {
            th.$store.dispatch('setUserData', { user })
            th.user = data

            if (th.tempo <= 0) {
              setTimeout(function () {
                th.startCountdown()
              }, 500)
            }

            const dataExpiracao = new Date(
              new Date().getTime() + data.expires * 60
            )
            const dataAtual = new Date()
            th.tempo = Math.trunc(
              (dataExpiracao.getTime() - dataAtual.getTime()) / 1000
            )
          } else {
            th.$api.UI.ShowError('Erro', 'Não foi possível renovar a sessão')
            setTimeout(th.logout(), 3000)
          }
        })
        .catch((err) => console.log(err))
    },
    getUserRole (jwtToken) {
      const jwtData = jwtToken.split('.')[1]
      const decodedJwtJsonData = window.atob(jwtData)
      return JSON.parse(decodedJwtJsonData).role
    }
  },
  mounted: function () {
    this.setExpirationTime()
    if (this.tempo > 0) this.startCountdown()
  }
}
</script>
