<template>
  <v-layout row justify-center data-cy="message-layout">
    <v-dialog v-model="show" persistent :width="600" @click.stop="close" data-cy="message-dialog">
      <v-card data-cy="message-card">
        <v-card-title
          data-cy="message-card-title"
          v-bind:class="classe + '--text pa-4'"
          class="headline title">
            <v-icon :color="classe" class="mr-2" data-cy="message-card-icon">{{ icone }}</v-icon>
            {{ titulo }}
          <div class="flex-grow-1"></div>
          <v-btn small icon @click="close" data-cy="message-close-button">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5" id="text" v-html="mensagem" data-cy="message-card-text"></v-card-text>
        <div class="date">{{dtInicial}}</div>
        <v-divider></v-divider>
        <v-card-actions>
          <div class="divButtons" v-show="!jaLido">
            <input type="checkbox" v-model="isLido" id="CheckBox"/>
            <label for="checkbox" id="textCheckbox">Estou de acordo, não mostrar novamente.</label> 
          </div>
          <v-spacer></v-spacer>
          <v-btn depressed :color="classe" @click="close" data-cy="card-action-button">
            {{ ok }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: {
    tipo: { default: 'info' },
    titulo: String,
    mensagem: String,
    ok: { default: 'Ok' },
    callback: Function,
    jaLido: { default: false },
    codigoNotificacao: String,
    dtInicial: String
  },
  data () {
    return {
      isLido: false,
      show: false
    }
  },
  methods: {
    close () {
      this.show = false
      this.jaLido = false
      if(this.isLido){
        this.$root.$emit('refreshBell', this.codigoNotificacao);
        this.LerNotificacao()
      }
    },
    openModal(){
      this.show = true
      this.isLido = false
    },
    LerNotificacao(){
      this.$api.Notificacao.LerNotificacao(this.codigoNotificacao)
    }
  },
  computed: {
    classe () {
      switch (this.tipo) {
        case 'info':
          return 'info'
        case 'alerta':
          return 'warning'
        case 'erro':
          return 'error'
        default:
          return 'info'
      }
    },
    icone () {
      switch (this.tipo) {
        case 'info':
          return 'info'
        case 'alerta':
          return 'warning'
        case 'erro':
          return 'error'
        default:
          return 'info'
      }
    }
  }
}
</script>

<style scoped>

#textCheckbox {
  font-size: 95%;
  margin-left: 5px;
}

#CheckBox{
  transform: scale(1.3);
}

.date{
  font-size: 14px;
  color: rgb(185, 185, 185);
  text-align: right;
  margin-right: 15px;
}

#text{
  color: rgb(40, 40, 34);
  margin-bottom: -18px;
}
</style>