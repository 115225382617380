<template>
  <div>
    <v-row>
      <v-col cols="6">
        <input-autocomplete
          label="Cedente"
          :disabled="disableCedente"
          v-model="model.codigoCedente"
          data-cy="cedentes"
          return-object
          :onRead="loadComboCedentes"
          no-data-text="Nenhum cedente foi encontrado."
          @change="loadComboCedentesCarteiras"
          :required="required"
          clearable
        />
      </v-col>
      <v-col cols="6">
        <input-select
          label="Cedente/Carteira"
          v-model="model.codigoCedenteCarteira"
          :options="cedentesCarteiras"
          :disabled="disableCarteira"
          @change="changeCarteira"
          :required="required"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      cedentesCarteiras: []
    };
  },
  props: {
    value: Object,
    disableCedente: { type: Boolean, default: false },
    disableCarteira: { type: Boolean, default: false },
    required: { type: Boolean, default: true}
  },
  computed: {
    model: {
      get () {
        var obj = this.value;
        return obj;
      },
      set (value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    loadComboCedentes (dto, callback) {
      const th = this;
      th.cedentes = [];
      th.$api.Cedentes.Combo(false).then(function (response) {
        if (!response.length || response.length === 0) {
          // eslint-disable-next-line standard/no-callback-literal
          callback([]);
        } else {
          callback(
            response.map(p => {
              return {
                value: p.guid,
                text: p.string
              };
            })
          );
        }
      });
    },
    loadComboCedentesCarteiras (val) {
      const th = this;
      th.cedentesCarteiras = [];
      if (val) {
        th.$api.CedentesCarteiras.Combo(val.value).then(function (data) {
          if (Array.isArray(data) && data.length > 0) {
            data.forEach(function (item) {
              th.cedentesCarteiras.push({
                value: item.guid,
                text: item.string
              });
            });
          } else {
            th.$api.UI.ShowAlert(
              'Erro',
              'Nao foram encontrados Carteiras relacionadas ao Cedente.'
            );
          }

          th.$emit('changeCedente', val);
        });
      }
    },
    changeCarteira (val) {
      this.$emit('changeCarteira', val);
    }
  }
};
</script>
