export default {
  TipoPerfilOperador: {
      Nenhum: 0,
      Consulta: 1,
      Administrador: 1 << 1,
      Analise: 1 << 2
  },
  TipoResponseContextoCadastros: {
    Sucesso: 0,
    Alerta: 1,
    Erro: 2
  },
  TipoPerfilOperadorOnboarding: {
    Nenhum: "Nenhum",
    Administrador: "Administrador",
    Analista: "Analista",
    Aprovador: "Aprovador"
  },
  OrigemBiometria: {
    Retaguarda: 1,
    FrontEnd: 2
  }
}

