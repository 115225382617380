<template>
  <modal-form class="custom-grid" :hideFooter="isHistorico" v-model="show" size="lg" ref="modalAnalise">
    <template v-slot:title>{{ acaoOnboarding[0].toUpperCase() + acaoOnboarding.substring(1) }} de operador {{ contexto
      }}
    </template>
    <template v-slot:body>
      <Confirmacao @close="response" ref="confirmaAnalise" :contextoConfirmacao="contexto" />
      <v-stepper alt-labels v-model="steps" flat tile class="custom-stepper">
        <v-stepper-header class="custom-stepper-header">
          <template>
            <v-stepper-step v-for="(name, index) in steppers" v-bind:key="name.id" ref="stepSpan" editable
              :step="index + 1">
              {{ name }}
            </v-stepper-step>
          </template>
        </v-stepper-header>

        <v-stepper-items class="custom-stepper-items">
          <v-stepper-content step="1">
            <core-form>
              <Resumo ref="resumo" :onboarding="onboardingResumo" />
            </core-form>
          </v-stepper-content>
          <v-stepper-content step="2">
            <core-form>
              <Informacoes ref="informacoes" :onboarding="onboardingAtual" />
            </core-form>
          </v-stepper-content>

          <v-stepper-content step="3">
            <core-form>
              <Solicitante ref="solicitante" :onboarding="onboardingAtual" :solicitante="solicitanteOnboarding" />
            </core-form>
          </v-stepper-content>

          <v-stepper-content step="4">
              <core-form>
                <AnaliseGeral ref="analiseGeral" :analises="analisesOnboarding"/>
              </core-form>
          </v-stepper-content> 

          <v-stepper-content v-for="(name, index) in items" ref="stepSpan" :key="index.id" :step="index + 5">
            <core-form ref="bureauDefault">
              <BureauDefault :bureau="bureaus[index]" :contexto="contexto" :onboarding="onboardingAtual" @reconsulta="reconsultar" :isHistorico="isHistorico" />
            </core-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
    <template v-slot:actions>
        <div class="flex-grow-1"></div>
        <v-btn color="error" @click="reprovar">Rejeitar</v-btn>
        <v-btn color="primary" @click="aprovar">Aprovar</v-btn>
    </template>
  </modal-form>
</template>

<script>
export default {
  props: {
    refresh: Function,
    contexto: String,
    isHistorico: Boolean
  },
  components: {
    Informacoes: () => import('./tabs/Informacoes'),
    Solicitante: () => import('./tabs/Solicitante'),
    BureauDefault: () => import('./tabs/Bureau'),
    AnaliseGeral: () => import('./tabs/AnaliseGeral'),
    Resumo: () => import('./tabs/Resumo'),
    Confirmacao: () => import('./AnaliseOperadorConfirmacao'),
  },
  data() {
    return {
      acaoOnboarding: 'acao',
      show: false,
      model: {},
      onboardingAtual: {},
      onboardingResumo: {},
      bureaus: [],
      solicitanteOnboarding: {},
      analisesOnboarding: [],
      steppers: [],
      items: [],
      steps: 1
    }
  },
  methods: {
    openModal(curr) {
      const th = this
      th.show = true
      th.limparCampos()
      th.steps = 1

      Object.assign(th.model, curr)
      th.acaoOnboarding = th.obterTipoPermissao(th.model.tipoPermissao)

      th.obterConsultas()
    },
    alimentarSteppers(bureausOnboaridng) {
      const th = this

      th.steppers = [];
      bureausOnboaridng.forEach((currBureau) =>
        th.steppers.push(th.$api.Arrays.TipoBureau.find((arr) => arr.value == currBureau.bureau)?.text)
      )

      th.steppers.unshift('Análise geral')
      th.steppers.unshift('Solicitante')
      th.steppers.unshift('Informacões')
      th.steppers.unshift('Resumo')
    },
    alimentarItems(bureausOnboarding) {
      const th = this
      th.alimentarItemsBureau(bureausOnboarding)
      th.$refs.solicitante.alimentarObjeto()
      th.$refs.informacoes.alimentarObjeto()
      th.$refs.analiseGeral.alimentarObjeto()
      th.$refs.resumo.alimentarObjeto()
    },
    alimentarItemsBureau(bureausOnboarding) {
      const th = this
      bureausOnboarding.forEach((currBureau) => th.items.push(currBureau))
    },
    obterTipoPermissao(tipoPermissao) {
      if (tipoPermissao == 1) return 'criacão'
      else if (tipoPermissao == 2) return 'edição'
      else if (tipoPermissao == 4) return 'vínculo'
      else if (tipoPermissao == 5) return 'vínculo'
    },
    aprovar() {
      const th = this
      th.model.acao = 'aprovação'
      th.$refs.confirmaAnalise.openModal(th.model)
    },
    reprovar() {
      const th = this
      th.model.acao = 'reprovação'
      th.$refs.confirmaAnalise.openModal(th.model)
    },
    response() {
      this.closeModal()
    },
    closeModal() {
      const th = this
      th.limparCampos()
      th.show = false
      th.$emit('close')
    },
    limparCampos(){
      const th = this
      th.model = {}
      th.onboardingAtual = {}
      th.solicitanteOnboarding = {}
      th.analisesOnboarding = []
      th.bureaus = []
      th.steppers = []
      th.items = []
    },
    reconsultar() {
      const th = this
      th.acaoOnboarding = th.obterTipoPermissao(th.model.tipoPermissao)
      th.obterConsultas()
    },
    obterConsultas(){
      const th = this

      th.$api['analise-onboarding'].ObterConsulta(th.model.codigo).then(function (response) {
        if (!th.$api.UI.PostLoadData(response))
          return

        Object.assign(th.onboardingAtual, response.onboarding)
        Object.assign(th.onboardingResumo, response.resumo)
        Object.assign(th.bureaus, response.detalhesBureaus)
        th.bureaus.sort(th.ordenarBureaus)

        Object.assign(th.solicitanteOnboarding, response.solicitante)
        Object.assign(th.analisesOnboarding, response.analiseGeral)
        th.alimentarSteppers(th.bureaus)
        th.alimentarItems(th.bureaus)
      });
    },
    ordenarBureaus(a, b){
      return a.bureau - b.bureau;
    }
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

// retira o numero e circulo dos steps
.v-stepper__step__step {
  display: none;
}

//configura a box de cada step
.v-stepper--alt-labels .v-stepper__step {
  align-items: start;
  flex-basis: 0;
  padding: 20px;
  min-width: 180px;
}

//configura o modal em 2 colunas
.custom-stepper {
  display: flex;
}

//configura o header na lateral esquerda
.custom-stepper-header {
  flex-direction: column;
  justify-content: start;
  flex: 0 0 20%;
  max-height: 60vh;
  flex-wrap: nowrap;
  overflow-y: scroll;
  overflow-x: hidden !important;
  min-width: 180px;
}

//configura o item na lateral direita
.custom-stepper-items {
  flex-direction: column;
  flex: 0 0 80%;
  height: 60vh;
  overflow-y: scroll;
}

// nao permite que o tamanho da tela apague as infos da coluna das esquerda do modal
.v-stepper__label{
  display: block !important
}
</style>
