<template>
  <input-text
    v-model="model"
    v-bind="$attrs"
    v-on="$listeners"
    v-mask="[
      '#####.##### #####.###### #####.###### # ##############',
      '############ ############ ############ ############',
    ]"
    :required="required"
    :disabled="disabled"
    :placeholder="placeholder"
  />
</template>

<script>
export default {
  props: {
    value: null,
    required: { default: false },
    disabled: { default: false },
    placeholder: { default: '' }
  },
  computed: {
    model: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>
