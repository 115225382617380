import util from '@bmp-vue/core/api/util'

const controller = 'bureau-config'
const versao = 'v1/'

export default Object.assign({}, util.GetCrud(controller, null), {
    Listar: (dto, options) => {
        if (options && options.data) dto.data = options.data
        return util.Axios.GetAll(versao + controller, dto)
    },
    Inserir: (dto) => {
        return util.Axios.Post(versao + controller, dto)
    },
    Atualizar: (codigo, request) => {
        return util.Axios.Put(versao + controller, codigo, request)
    }
})