<template>
  <div>
    <div v-show="mostrarCamposPrincipais">
      <b><span>{{tituloCustomizadoCamposPrincipais}}</span></b>
      <div v-for="(valor, chave) in camposPrincipais" :key="chave"> <strong>{{ chave }}</strong>: {{ valor }} </div>
      <br>
    </div>
    <div v-show="mostrarCamposEditados">
      <b><span>{{tituloCustomizadoCamposEditados}}</span></b>
      <div v-for="(valor, chave) in camposEditados" :key="chave"> <strong>{{ chave }}</strong>: {{ valor }} </div>
      <br>
    </div>
    <div v-show="mostrarCamposAtuais">
      <b><span v-show="mostrarCamposAtuais">{{tituloCustomizadoCamposAtuais}}</span></b>
      <div v-for="(valor, chave) in camposAtuais" :key="chave"> <strong>{{ chave }}</strong>: {{ valor }} </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    refresh: Function,
    onboarding: Object
  },
  data() {
    return {
      onboardingEntidade: {},
      mostrarCamposAtuais: false,
      mostrarCamposEditados: false,
      mostrarCamposPrincipais: false,
      camposAtuais: {},
      camposEditados: {},
      camposPrincipais: {},
      tituloCustomizadoCamposAtuais: 'Campos Atuais:',
      tituloCustomizadoCamposEditados: 'Campos Editados:',
      tituloCustomizadoCamposPrincipais: 'Informações:',
    }
  },
  methods: {
    alimentarObjeto() {
      const th = this
      th.limparCampos()
      Object.assign(th.onboardingEntidade, th.onboarding)

      if(th.onboardingEntidade.tipoPermissao == th.$api.Arrays.TipoPermissao[0].value)
        th.alimentarCriacao()

      if(th.onboardingEntidade.tipoPermissao == th.$api.Arrays.TipoPermissao[1].value)
        th.alimentarEdicao()

      if(th.onboardingEntidade.tipoPermissao == th.$api.Arrays.TipoPermissao[2].value 
        || th.onboardingEntidade.tipoPermissao == th.$api.Arrays.TipoPermissao[3].value)
        th.onboardingEntidade.contexto == th.$api.Arrays.ContextoOnboarding[3].value ? th.alimentarVinculoParceiro() : th.alimentarVinculoConta() 
    },
    alimentarCriacao(){
      const th = this

      th.mostrar(false, true, true)
      th.alimentarCamposPrincipais(th.onboardingEntidade.jsonNovo)
      th.camposEditados = JSON.parse(th.onboardingEntidade.jsonNovo ?? "{}")
      th.verificarCamposVazios()
    },
    alimentarEdicao(){
      const th = this

      th.mostrar(true, true, true)
      th.alimentarCamposPrincipais(th.onboardingEntidade.jsonAtual)
      th.camposEditados = JSON.parse(th.onboardingEntidade.jsonNovo ?? "{}")
      th.camposAtuais = JSON.parse(th.onboardingEntidade.jsonAtual ?? "{}")
      th.verificarCamposVazios()

      th.camposEditados['Permissão Feriados'] = th.camposEditados['Permissão Feriados'] != true ? 'Não' : 'Sim'
      th.camposAtuais['Permissão Feriados'] = th.camposAtuais['Permissão Feriados'] != true ? 'Não' : 'Sim'

      const acessoPadrao =  th.camposEditados['Tipo de Acesso'] == 'Padrão'
      
      for (const key in th.camposEditados) {
        
        if (!acessoPadrao && th.camposEditados[key] != th.camposAtuais[key])   
          continue;
        
        let campoInvalido = key == 'Horário de Ativação' || key == 'Permissão Feriados' || key == 'Dias de Acesso' || key == 'Horário de Inativação' 
        if (th.camposEditados[key] != th.camposAtuais[key] && !campoInvalido)
          continue

        delete th.camposEditados[key]
        delete th.camposAtuais[key]
      }
    },
    alimentarVinculoParceiro(){
      const th = this

      th.mostrar(true, true, true)
      th.alimentarCamposPrincipais(th.onboardingEntidade.jsonNovo)
      th.camposEditados= JSON.parse(th.onboardingEntidade.jsonNovo ?? "{}")
      th.camposAtuais= JSON.parse(th.onboardingEntidade.jsonAtual ?? "{}")
      th.verificarCamposVazios()

      for (const key in th.camposEditados) {
        if (key != "Parceiro"){
          delete th.camposEditados[key]
          delete th.camposAtuais[key]
        }
      }
    },
    alimentarVinculoConta(){
      const th = this

      th.mostrar(true, true, true)
      th.alimentarCamposPrincipais(th.onboardingEntidade.jsonAtual)
      th.camposEditados= JSON.parse(th.onboardingEntidade.jsonNovo ?? "{}")
      th.camposAtuais= JSON.parse(th.onboardingEntidade.jsonAtual ?? "{}")
      th.verificarCamposVazios()

      for (const key in th.camposEditados) {
        if (key != "Conta"){
          delete th.camposEditados[key]
          delete th.camposAtuais[key]
        }
      }
    },
    alimentarCamposPrincipais(curr){
      const th = this

      th.camposPrincipais = JSON.parse(curr ?? "{}")

      for (const key in th.camposPrincipais) {
        if (key != "Nome Operador" && key != "Documento Federal")
          delete th.camposPrincipais[key]
      }
    },
    mostrar(atuais, editados, principais){
      const th = this
      th.mostrarCamposAtuais = atuais
      th.mostrarCamposEditados = editados
      th.mostrarCamposPrincipais = principais
    },
    verificarCamposVazios(){
      const th = this

      th.preencherCamposVazios(th.camposAtuais)
      th.preencherCamposVazios(th.camposEditados)
      th.preencherCamposVazios(th.camposPrincipais)
    },  
    preencherCamposVazios(curr){
      if(curr == {})
        return;
      
      Object.entries(curr).forEach(([chave, valor]) => {
        if(valor == null || valor == undefined || valor == '')
        curr[chave]= 'Não preenchido'
      });
    },    
    limparCampos(){
      const th = this
      th.mostrarCamposAtuais = false
      th.mostrarCamposEditados=  false
      th.mostrarCamposPrincipais = false
      th.camposAtuais = {}
      th.camposEditados =  {}
      th.camposPrincipais =  {}
    }
  },
}
</script>
