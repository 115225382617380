<template>
  <modal-form :submit="submit" v-model="show" size="md">
    <template v-slot:title> Solicitação Relatório </template>
    <template v-slot:body>
      <v-row>
        <v-col class="pb-0" cols="12" md="6">
          <input-select
            v-model="model.tipoArquivo"
            :options="tipoArquivoRelatorio"
            label="Tipo Arquivo"
            required
            single
          />
        </v-col>
        <v-col class="pb-0" cols="12" md="6">
          <v-switch
            class="mt-0 mx-3"
            inset
            v-model="model.enviarEmail"
            label="Permitir notificação via email"
          />
        </v-col>
      </v-row>
    </template>
  </modal-form>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      model: {
        enviarEmail: false
      }
    };
  },
  props: {
    tipoArquivoRelatorio: Array
  },
  methods: {
    openModal (sender) {
      this.show = true;
    },
    submit () {
      this.show = false
      this.$emit('solicitarRelatorio', this.model);
    }
  }
};
</script>
