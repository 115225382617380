<template>
  <div>
    <b><span>Solicitante:</span></b>
    <br><br>
    <div>
      <div>
        <span> <strong>Nome:</strong> {{ solicitanteAtual.nome }} </span><br>
        <span> <strong>Email:</strong> {{ solicitanteAtual.email }} </span><br>
        <span> <strong>Documento Federal:</strong> {{ solicitanteAtual.documentoFederal }} </span><br>
        <span> <strong>Ip Origem:</strong> {{ solicitanteAtual.ipOrigem }} </span><br>
        <span> <strong>Data Solicitação:</strong> {{ solicitanteAtual.dataSolicitacao }} </span><br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    refresh: Function,
    onboarding: Object,
    solicitante: Object
  },
  data() {
    return {
    model:{},
    onboardingEntidade: {},
    solicitanteEntidade: {},
    solicitanteAtual: {},
    bloquearConsulta: false
    }
    },
    methods: {
      alimentarObjeto() {
        let th = this
        Object.assign(th.onboardingEntidade, th.onboarding)
        Object.assign(th.solicitanteEntidade, th.solicitante)

        th.solicitanteAtual = {
          email: th.solicitanteEntidade.email,
          ipOrigem: th.onboardingEntidade.ipOrigem,
          documentoFederal: th.$api.UI.Format.DocumentoFederal(th.solicitanteEntidade.documentoFederal),
          dataSolicitacao: th.$api.UI.Format.DateTime(th.onboardingEntidade.dtInclusao),
          nome: th.solicitanteEntidade.nome
        }
    },
  },
}
</script>
<style scoped>
</style>
