<template>
  <div>
    <modal-form v-model="show" size="md" :showSubmit="false">
      <template v-slot:title> QRCode </template>
      <template v-slot:body>
        <QRCodeBody ref="qrcode_body"
                    :labelText="labelText"
                    :placeHolderText="placeHolderText"/>
      </template>
      <template slot="actions.append">
        <QRCodeButton ref="qrcode_button"/>
      </template>
    </modal-form>
  </div>
</template>
<script>

export default {
  props: {
    labelText: { type: String, default: 'Copia & Cola (EMV)' },
    placeHolderText: { type: String, default: 'Copia & Cola (EMV)' },
  },
  components: {
    QRCodeBody: () => import('./QRCodeComponents/QRCodeBody'),
    QRCodeButton: () => import('./QRCodeComponents/QRCodeButton')
  },
  data () {
    return {
      show: false,
      model: { emv: '', imagem: '' }
    };
  },
  methods: {
    openModal (inputText, sourceImage) {
      this.setModel(inputText, sourceImage)
      this.show = true;
    },
    close () {
      this.show = false;
      this.model = {};
    },
    setModel (inputText, sourceImage) {
      var th = this
      th.$nextTick(() => {
        th.$set(th.$refs.qrcode_body.model, 'inputText', inputText);
        th.$set(th.$refs.qrcode_body.model, 'sourceImage', sourceImage);

        th.$set(th.$refs.qrcode_button.model, 'inputText', inputText);
        th.$set(th.$refs.qrcode_button.model, 'sourceImage', sourceImage);
      });
    }
  }
};
</script>

<style scoped>
::v-deep .qrcode-image {
  width: 70%;
  height: auto;
}
</style>
