<template ref="bell">
  <div id="bell" ref="notificacaoRef">
    <div class="notification">
      <a href="#">
        <div class="notBtn" href="#">
          <div class="number" v-if="qtdNotificacoes">{{qtdNotificacoes}}</div>
          <notification-bell class="bell-icon" :size="18" />
          <div class="box">
            <div class="display">
              <li class="title">
                <p id="titleId">Notificações</p>
              </li>
              <li
                v-for="(notificacao, index) in notificacoes"
                :key="index"
                :class="`columns ${notificacao.codigo}`"
                @click="abrirModal(notificacao)"
              >
                <div id="tituloModal">{{notificacao.titulo}}</div>
                <div id="dataModal">{{notificacao.dtInicial}}</div>
              </li>
            </div>
          </div>
        </div>
      </a>
      <dbs-notificacao
        :titulo="model.titulo"
        :mensagem="model.descricao"
        :dtInicial="model.dtInicial"
        :jaLido="jaLido"
        ref="notificacao"
        :codigoNotificacao="model.codigoNotificao"
      ></dbs-notificacao>
    </div>
  </div>
</template>

<script>
import NotificationBell from "vue-notification-bell";

export default {
  data() {
    return {
      model: {
        titulo: "",
        descricao: "",
        dtInicial: ""
      },
      show: false,
      jaLido: false,
      qtdNotificacoes: 0,
      notificacoes: []
    };
  },
  components: {
    NotificationBell
  },
  methods: {
    abrirModal(notificacao) {
      let th = this;

      if (notificacao.situacaoNotificacao == 3) th.jaLido = true;
      else th.jaLido = false;

      th.model = {};
      th.model.titulo = notificacao.titulo;
      th.model.descricao = notificacao.descricao;
      th.model.codigoNotificao = notificacao.codigo;
      th.model.dtInicial = notificacao.dtInicial;
      th.$refs.notificacao.openModal();
    },
    VerificaLeitura() {
      var notificacaoAtiva = 0

      this.notificacoes.forEach(element => {
        var borda = document.getElementsByClassName(String(element.codigo))[0];
       if (element.situacaoNotificacao == 1){
          borda.style.borderLeft = "5px solid #d63031";
          notificacaoAtiva++
        } else {
          borda.style.borderLeft = "0px";
        }
      });
      return notificacaoAtiva
    },
    OpenBell(){
      const th = this;
      th.notificacoes = []

      th.$api.Notificacao.GetNotificacaoOperador().then(function(data) {
      if (Array.isArray(data)) {
          data.forEach(function (item) {
            let notificacao = {
              titulo: item.titulo,
              descricao: item.descricao,
              situacaoNotificacao: item.situacaoNotificacao,
              codigo: item.codigo,
              dtInicial: item.dtInicial
            };
            let data = new Date(notificacao.dtInicial)
            notificacao.dtInicial = data.toLocaleDateString()
            
            th.notificacoes.push(notificacao);
          }
      )}
      setTimeout(() => {
          th.qtdNotificacoes = th.VerificaLeitura();
      }, 200);
      })
    },
    RefreshBell(codigoNotificacao){
      const th = this;
      var indexNotificacao = null;

      th.notificacoes.forEach((element, index) => {
        if(element.codigo == codigoNotificacao){
          indexNotificacao = index
        }
      })
      if(indexNotificacao >= 0){
        th.notificacoes[indexNotificacao].situacaoNotificacao = 3
        th.qtdNotificacoes = this.VerificaLeitura();
      }
    }
  },
  created() {
    this.OpenBell();
    this.$root.$on("refreshBell", (codigoNotificacao) => this.RefreshBell(codigoNotificacao));
  }
};
</script>

<style scoped>
.bell-icon {
  margin-right: 20px;
  position: absolute;
  bottom: -1px;
  left: -45px;
}

.columns {
  padding: 10px;
  border-bottom: 1px solid rgb(155, 155, 155);
  line-height: 15px;
  list-style-type: none;
}

.columns:hover {
  background-color: rgb(233, 233, 233);
  transition: 0.5s;
  cursor: pointer;
}

#tituloModal {
  margin-top: 10px;
  margin-left: 10px;
}

#dataModal {
  font-size: 12px;
  color: #8f8f8f;
  margin-bottom: 0px;
  text-align: right;
}

.title {
  background-color: rgb(46, 50, 58);
  list-style-type: none;
}

#titleId {
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 0px;
  color: #f8f8f8;
}

a {
  text-decoration: none;
  color: black;
}

a:visited {
  color: black;
}

.box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 5px;
}

.box::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.box::-webkit-scrollbar-thumb {
  background-color: rgb(194, 194, 194);
  border: 2px solid rgb(194, 194, 194);
  border-radius: 5px;
}

header {
  -moz-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
  height: 110px;
  vertical-align: middle;
}

h1 {
  float: left;
  padding: 10px 30px;
}

.icons {
  display: inline;
}

.notification {
  padding-top: 30px;
  position: relative;
  display: inline-block;
}

.number {
  height: 28px;
  width: 28px;
  background-color: #d63031;
  border-radius: 20px;
  color: white;
  text-align: center;
  position: absolute;
  top: -7px;
  left: -35px;
  border-style: solid;
  border-width: 2px;
}

.number:empty {
  display: none;
}

.notBtn {
  transition: 0.5s;
  cursor: pointer;
}

.fas {
  font-size: 25pt;
  padding-bottom: 10px;
  color: black;
  margin-right: 40px;
  margin-left: 40px;
}

.box {
  width: 400px;
  height: 0px;
  border-radius: 5px;
  transition: 0.5s;
  position: absolute;
  overflow-y: scroll;
  padding: 0px;
  left: -300px;
  margin-top: 5px;
  background-color: #f9f9f9;
  -webkit-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
  cursor: context-menu;
}

.fas:hover {
  color: #d63031;
}

.notBtn:hover > .box {
  height: 60vh;
}

.content {
  padding: 20px;
  color: black;
  vertical-align: middle;
  text-align: left;
}

.gry {
  background-color: #f4f4f4;
}

.top {
  color: black;
  padding: 10px;
}

.display {
  position: relative;
}

.cont {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
}

.cont:empty {
  display: none;
}

.stick {
  text-align: center;
  display: block;
  font-size: 50pt;
  padding-top: 70px;
  padding-left: 80px;
}

.stick:hover {
  color: black;
}

.cent {
  text-align: center;
  display: block;
}

.sec {
  padding: 25px 10px;
  background-color: #f4f4f4;
  transition: 0.5s;
}

.profCont {
  padding-left: 15px;
}

.profile {
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
  width: 75px;
  float: left;
}

.txt {
  vertical-align: top;
  font-size: 1.25rem;
  padding: 5px 10px 0px 115px;
}

.sub {
  font-size: 1rem;
  color: grey;
}

.new {
  border-style: none none solid none;
  border-color: red;
}

.sec:hover {
  background-color: #bfbfbf;
}
</style>
