import util from '@bmp-vue/core/api/util'

const controller = 'autenticacao'
const versao = 'v1/'

export default Object.assign({}, util.GetCrud(controller, ['none']), {
    AutenticarOperador: ({ email, password, tokenCaptcha }) => {
        var dto = { Login: email, Senha: password, TokenCaptcha: tokenCaptcha }
        return util.Axios.Post(versao + controller + '/access-token', dto)
    },
    DesativarAccessTokenAsync: () => {
        return util.Axios.Post(versao + controller + '/desativar-access-token')
    },
    RefreshToken: (request) => {
        return util.Axios.Post(versao + controller + '/refresh-token', { refreshToken: request })
    },
    Logout: () => {
        return util.Axios.Post(versao + controller + '/desativar-refresh-token')
    },
})