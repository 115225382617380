import util from '@bmp-vue/core/api/util'

const controller = 'mfa-fisico'
const versao = 'v1/'

export default Object.assign({}, util.GetCrud(controller, ['none']), {
    SolicitarValidacaoChave: (dto) => {
      return util.Axios.Get(versao + controller + '/solicitar/validacao')
    },
    ValidarToken: (challengeResponse) => {
      return util.Axios.Post(versao + controller + '/validar', challengeResponse)
    },
    SolicitarRegistroMfaFisico: (email) =>{
      return util.Axios.Post(versao + controller + `/solicitar/${email}`, {})
    },
    RegistrarMfaFisico: (dto) => {
      return util.Axios.Post(versao + controller + '/registrar', dto)
    }
})