<template>
    <div>
        <v-row>
          <v-col cols="12">
            <input-text
              class="mb-n12"
              v-model="model.inputText"
              :label="labelText"
              :placeholder="placeHolderText"
              readonly
            >
            </input-text>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" offset="2">
            <v-img
              class="qrcode-image"
              :src="`${model.sourceImage}`"
              alt="QRCode"
            ></v-img
          ></v-col>
        </v-row>
    </div>
</template>

<script>
export default {
  props: {
    labelText: { type: String, default: 'Copia & Cola (EMV)' },
    placeHolderText: { type: String, default: 'Copia & Cola (EMV)' }
  },
  data () {
    return {
      model: {
        sourceImage: '',
        inputText: ''
      }
    }
  }
};
</script>
