import util from '@bmp-vue/core/api/util'

const controller = 'historico'
const versao = 'v1'

export default Object.assign({}, util.GetCrud(controller, null), {
	Listar: function (dto) {
		return util.Axios.GetAll(`${versao}/${controller}`, dto)
	},
	BuscarImagensKYC: function (codigoOnboarding, origemBiometria) {
		return util.Axios.Get(`${versao}/${controller}/${codigoOnboarding}/link-imagens-kyc?origemBiometria=${origemBiometria}`)
	},
	BuscarConsultaCAF: function (codigoOnboarding) {
		return util.Axios.Get(`${versao}/${controller}/${codigoOnboarding}/consulta-caf`)
	},
	ListarParecerAprovador: function (codigoOnboarding) {
		return util.Axios.Get(`${versao}/${controller}/${codigoOnboarding}/parecer-aprovador`)
	}
})