import ui from "@bmp-vue/core/api/ui";
import enums from '../enum'

export default {
  FomatarData: (dataString, horario) => {
      const data = new Date(dataString);

      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const ano = data.getFullYear();

      if (horario === true) {
        const hora = String(data.getHours()).padStart(2, '0');
        const minuto = String(data.getMinutes()).padStart(2, '0');
        const segundo = String(data.getSeconds()).padStart(2, '0');

        return `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;
      }

      return `${dia}/${mes}/${ano}`;
    },
    CustomPostAction: (response) => {
      ui.HideLoading()
      const tipoResponse = enums.TipoResponseContextoCadastros

      if (response.tipoMensagem === tipoResponse.Alerta) {
          ui.ShowAlert(response.titulo || "Atenção", response.mensagem || '')
          return true
      }

      if (response.tipoMensagem === tipoResponse.Sucesso) {
          ui.ShowSuccess(response.titulo || "Sucesso", response.mensagem || 'Operação Realizada com Sucesso!')
          return true
      }

      ui.ShowError(response.titulo || 'Erro', response.mensagem || "Ocorreu um erro na requisição")
      return false
    }
}